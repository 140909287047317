import ReactDOM from "react-dom/client";
import { StrictMode } from "react";
import "./index.css";
import App from "./App";
import { Amplify } from "aws-amplify";
import reportWebVitals from "./reportWebVitals";
import { Authenticator } from "@aws-amplify/ui-react";
import amplifyconfig from "./amplifyconfiguration.json";
import { QueryClient, QueryClientProvider } from "react-query";

Amplify.configure(amplifyconfig);

const client = new QueryClient();
const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

root.render(
  <StrictMode>
    <Authenticator.Provider>
      <QueryClientProvider client={client}>
        <App />
      </QueryClientProvider>
    </Authenticator.Provider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
