import { useAuthenticator } from "@aws-amplify/ui-react";

import "@aws-amplify/ui-react/styles.css";
import { Login } from "./components/Login/Login";
import { Dashboard } from "./components/Dashboard/Dashboard";

export default function App({ signOut }) {
  const { user } = useAuthenticator();

  if (user) {
    return <Dashboard />;
  }

  return <Login />;
}
