import { Authenticator, Flex, Grid, useTheme } from "@aws-amplify/ui-react";

import { Header } from ".././Header/Header";
import { Footer } from ".././Footer/Footer";
import { SignInHeader } from ".././SignInHeader/SignInHeader";
import { SignInFooter } from ".././SignInFooter/SignInFooter";

const components = {
  Header,
  SignIn: {
    Header: SignInHeader,
    Footer: SignInFooter,
  },
  Footer,
};

export function Login() {
  const { tokens } = useTheme();

  return (
    <Grid>
      <Flex
        backgroundColor={tokens.colors.background.primary}
        justifyContent="center"
      >
        <Authenticator components={components} hideSignUp>
          {({ signOut, user }) => (
            <main>
              <h1>Welcome {user.username}</h1>
              <button onClick={signOut}>Sign out</button>
            </main>
          )}
        </Authenticator>
      </Flex>
    </Grid>
  );
}
