import { useMemo, useState } from "react";
import {
  MaterialReactTable,
  // createRow,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { v4 as uuidv4 } from "uuid";
import { listNotices } from "../../graphql/queries";
import {
  updateNotice,
  createNotice,
  deleteNotice,
} from "../../graphql/mutations";
import EditIcon from "@mui/icons-material/Edit";
import NoticeIcon from "@mui/icons-material/PostAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import Container from "@mui/material/Container";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import { generateClient } from "aws-amplify/api";
import { uploadData, list } from "aws-amplify/storage";

const S3BucketUrl = "https://darkstarcdn.s3.us-east-2.amazonaws.com/public/";
const pdfSourcePrefixUrl =
  "https://docs.google.com/viewerng/viewer?url=https://darkstarcdn.s3.us-east-2.amazonaws.com/public/";

const client = generateClient();

export const NoticeTable = ({ clientId, clientPrefix }) => {
  const [validationErrors, setValidationErrors] = useState({});
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorContent, setErrorContent] = useState("");
  const [successContent, setSuccessContent] = useState("");
  const [successContentTitle, setSuccessContentTitle] = useState("");
  const [errorOpen, setErrorOpen] = useState(true);
  const [successOpen, setSuccessOpen] = useState(true);
  const [pdfUrls, setPdfUrls] = useState([]);
  const [imgUrls, setImgUrls] = useState([]);

  const noticeGroups = [
    {
      id: 1,
      title: "Current Notice",
    },
    {
      id: 2,
      title: "Archived Notice",
    },
  ];

  const clientPdfFolderPrefix = clientPrefix + "/pdf/";
  const clientImgFolderPrefix = clientPrefix + "/img/";

  const onFileUpload = async (file) => {
    try {
      let prefix, contentType;
      if (file.type === "image/jpeg") {
        prefix = clientImgFolderPrefix;
        contentType = "image/jpeg";
      } else {
        prefix = clientPdfFolderPrefix;
        contentType = "document/pdf";
      }

      const response = await uploadFileToStorage(prefix, contentType, file);

      if (file.type === "image/jpeg") {
        imgUrls.push(getStringAfterLastSlash(response.key));
        setImgUrls(imgUrls);
      } else {
        pdfUrls.push(getStringAfterLastSlash(response.key));
        setPdfUrls(pdfUrls);
      }

      setSuccessContentTitle(file.name);
      setSuccessContent("was successfully uploaded to the cloud storage!");
      setSuccess(true);
    } catch (error) {
      setErrorContent(error);
      setError(true);
    }
  };

  const uploadFileToStorage = async (prefix, contentType, file) => {
    const path = prefix + file.name;
    return uploadData({
      key: path,
      data: file,
      options: {
        contentType: Blob,
      },
    });
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "title",
        header: "Notice Title",
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.title,
          helperText: validationErrors?.title,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              title: undefined,
            }),
          //optionally add validation checking for onBlur or onChange
        },
      },
      {
        accessorKey: "groupId",
        header: "Notice Group",
        editSelectOptions: noticeGroups.map((group) => group.title),
        editVariant: "select",
        muiEditTextFieldProps: {
          select: true,
          required: true,
          error: !!validationErrors?.groupId,
          helperText: validationErrors?.groupId,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              groupId: undefined,
            }),
        },
      },
      {
        accessorKey: "pdfSource",
        header: "PDF Source",
        editSelectOptions: pdfUrls,
        editVariant: "select",
        muiEditTextFieldProps: {
          select: true,
          required: true,
          error: !!validationErrors?.pdfSource,
          helperText: validationErrors?.pdfSource,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              pdfSource: undefined,
            }),
        },
      },
      {
        accessorKey: "imgSource",
        header: "Image Source",
        editSelectOptions: imgUrls,
        editVariant: "select",
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.imgSource,
          helperText: validationErrors?.imgSource,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              imgSource: undefined,
            }),
        },
      },
      {
        accessorKey: "id",
        header: "Id",
        enableEditing: false,
        size: 80,
      },
    ],
    [validationErrors]
  );

  //call CREATE hook
  const { mutateAsync: createNotice, isPending: isCreatingNotice } =
    useCreateNotice(
      clientId,
      noticeGroups,
      clientPdfFolderPrefix,
      clientImgFolderPrefix
    );
  //call READ hook
  const {
    data: fetchedNotices = [],
    isError: isLoadingNoticesError,
    isFetching: isFetchingNotices,
    isLoading: isLoadingNotices,
  } = useGetNotices(
    clientId,
    clientPrefix,
    setPdfUrls,
    setImgUrls,
    noticeGroups
  );
  //call UPDATE hook
  const { mutateAsync: updateNotice, isPending: isUpdatingNotice } =
    useUpdateNotice(noticeGroups, clientPdfFolderPrefix, clientImgFolderPrefix);
  //call DELETE hook
  const { mutateAsync: deleteNotice, isPending: isDeletingNotice } =
    useDeleteNotice();

  //CREATE action
  const handleCreateNotice = async ({ values, table }) => {
    const newValidationErrors = validateNotice(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await createNotice(values);
    table.setCreatingRow(null); //exit creating mode
  };

  //UPDATE action
  const handleSaveNotice = async ({ values, table }) => {
    const newValidationErrors = validateNotice(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await updateNotice(values);
    table.setEditingRow(null); //exit editing mode
  };

  //DELETE action
  const openDeleteConfirmModal = (row) => {
    if (window.confirm("Are you sure you want to delete this notice?")) {
      deleteNotice(row.original.id);
    }
  };

  const table = useMaterialReactTable({
    columns,
    data: fetchedNotices,
    createDisplayMode: "row",
    editDisplayMode: "row",
    enableEditing: true,
    getRowId: (row) => row.id,
    muiToolbarAlertBannerProps: isLoadingNoticesError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateNotice,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveNotice,
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Edit Notice">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete Notice">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        variant="contained"
        startIcon={<NoticeIcon />}
        onClick={() => {
          table.setCreatingRow(true); //simplest way to open the create row modal with no default values
          //or you can pass in a row object to set default values with the `createRow` helper function
          // table.setCreatingRow(
          //   createRow(table, {
          //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
          //   }),
          // );
        }}
      >
        Create New Notice
      </Button>
    ),
    state: {
      isLoading: isLoadingNotices,
      isSaving: isCreatingNotice || isUpdatingNotice || isDeletingNotice,
      showAlertBanner: isLoadingNoticesError,
      showProgressBars: isFetchingNotices,
    },
  });

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <Container maxWidth="false">
      {error ? (
        <Collapse in={errorOpen}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {errorContent}
          </Alert>
        </Collapse>
      ) : (
        <></>
      )}

      {success ? (
        <Collapse in={successOpen}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSuccessOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            <AlertTitle>{successContentTitle}</AlertTitle>
            {successContent}
          </Alert>
        </Collapse>
      ) : (
        <></>
      )}
      <Box sx={{ padding: "1em 0em 1em" }}>
        <Button
          color="success"
          component="label"
          variant="contained"
          type="file"
          startIcon={<CloudUploadIcon />}
          onChange={(e) => onFileUpload(e.target.files[0])}
        >
          Upload file
          <VisuallyHiddenInput type="file" accept=".pdf, .jpg" />
        </Button>
      </Box>
      <MaterialReactTable table={table} />
    </Container>
  );
};

//CREATE hook
function useCreateNotice(
  clientId,
  noticeGroups,
  clientPdfFolderPrefix,
  clientImgFolderPrefix
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (notice) => {
      notice.id = uuidv4();
      notice.clientId = clientId;
      notice.groupId = noticeGroups.find(
        (group) => group.title === notice.groupId
      ).id;
      notice.pdfSource =
        pdfSourcePrefixUrl +
        clientPdfFolderPrefix +
        encodeURIComponent(notice.pdfSource);
      notice.imgSource =
        S3BucketUrl +
        clientImgFolderPrefix +
        encodeURIComponent(notice.imgSource);
      await client.graphql({
        query: createNotice,
        variables: { input: notice },
      });
      return Promise.resolve();
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["notices"] }), //refetch notices after mutation
  });
}

function useGetPdfUrls(clientPrefix, setPdfUrls) {
  return useQuery({
    queryKey: ["pdfUrls"],
    queryFn: async () => {
      try {
        const results = await list({
          prefix: clientPrefix + "/pdf/",
        });
        const urls = results.items
          .filter((res) => res.size)
          .map((res) => res.key)
          .map((url) => getStringAfterLastSlash(url));
        setPdfUrls(urls);
      } catch (error) {
        console.error("Error fetching PDF URLs:", error);
      }
    },
    refetchOnWindowFocus: false,
  });
}

function getStringAfterLastSlash(url) {
  const lastIndex = url.lastIndexOf("/");
  if (lastIndex === -1) {
    return url;
  }
  return url.substring(lastIndex + 1);
}

function useGetImgUrls(clientPrefix, setImgUrls) {
  return useQuery({
    queryKey: ["imgUrls"],
    queryFn: async () => {
      try {
        const results = await list({ prefix: clientPrefix + "/img/" });
        const urls = results.items
          .filter((res) => res.size)
          .map((res) => res.key)
          .map((url) => getStringAfterLastSlash(url));
        setImgUrls(urls);
      } catch (error) {
        console.error("Error fetching PDF URLs:", error);
      }
    },
    refetchOnWindowFocus: false,
  });
}

//READ hook
function useGetNotices(
  clientId,
  clientPrefix,
  setPdfUrls,
  setImgUrls,
  noticeGroups
) {
  useGetPdfUrls(clientPrefix, setPdfUrls);
  useGetImgUrls(clientPrefix, setImgUrls);
  return useQuery({
    queryKey: ["notices"],
    queryFn: async () => {
      const noticesData = await client.graphql({
        query: listNotices,
        variables: {
          limit: 20,
        },
      });
      const noticesList = noticesData.data.listNotices.items
        .filter((item) => item.clientId === clientId)
        .map((notice) => {
          return {
            ...notice,
            groupId: noticeGroups.find((group) => group.id === notice.groupId)
              .title,
            pdfSource: decodeURIComponent(
              getStringAfterLastSlash(notice.pdfSource)
            ),
            imgSource: decodeURIComponent(
              getStringAfterLastSlash(notice.imgSource)
            ),
          };
        });
      return Promise.resolve(noticesList);
    },
    refetchOnWindowFocus: false,
  });
}

//UPDATE hook
function useUpdateNotice(
  noticeGroups,
  clientPdfFolderPrefix,
  clientImgFolderPrefix
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (notice) => {
      notice.groupId = noticeGroups.find(
        (group) => group.title === notice.groupId
      ).id;
      notice.pdfSource =
        pdfSourcePrefixUrl +
        clientPdfFolderPrefix +
        encodeURIComponent(notice.pdfSource);
      notice.imgSource =
        S3BucketUrl +
        clientImgFolderPrefix +
        encodeURIComponent(notice.imgSource);
      await client.graphql({
        query: updateNotice,
        variables: { input: notice },
      });
      return Promise.resolve();
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["notices"] }), //refetch notices after mutation
  });
}

//DELETE hook
function useDeleteNotice() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (noticeId) => {
      await client.graphql({
        query: deleteNotice,
        variables: { input: { id: noticeId } },
      });
      return Promise.resolve();
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["notices"] }), //refetch notices after mutation
  });
}

const queryClient = new QueryClient();

const NoticeTableWithProviders = () => (
  //Put this with your other react-query providers near root of your app
  <QueryClientProvider client={queryClient}>
    <NoticeTable />
  </QueryClientProvider>
);

export default NoticeTableWithProviders;

const validateRequired = (value) => !!value.length;

function validateNotice(notice) {
  return {
    title: !validateRequired(notice.title) ? "Notice title is Required" : "",
    groupId: !validateRequired(notice.groupId)
      ? "Notice groupId is Required"
      : "",
    pdfSource: !validateRequired(notice.pdfSource)
      ? "PDF source is Required"
      : "",
    imgSource: !validateRequired(notice.imgSource)
      ? "Image source is Required"
      : "",
  };
}
