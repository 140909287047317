import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Image, useAuthenticator } from "@aws-amplify/ui-react";
import { NoticeTable } from "../NoticeTable/NoticeTable";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import { Button, Box, Divider, ListItemButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import ParkingStallClosuresEditor from "../ParkingStallClosuresEditor/ParkingStallClosuresEditor";

const testClientId = "1ea767ef-68fc-4053-9a74-583055598edd";
const castlesurfClientId = "9cf0d62b-479b-4f4d-9d16-558527e23449";
const wailanaClientId = "4e276952-164d-4300-8ffc-ddbb8c92383f";
const patsClientId = "9cf0d62b-479b-4f4d-9d16-558527ed3f49";
const haleopumehanaClientId = "77ff5d4e-a3d1-427b-b32e-af6e77fddb0d";
const kahalemoiClientId = "bf8086a5-f898-4d11-adf0-84c5b078ede1";
const konareefresortClientId = "1598dfe6-bafc-4e50-96f5-49aee07d789c";
const lakesidecoronetClientId = "3a45bbc4-d53f-4c2f-92ce-009f970725e7";
const palmsClientId = "9e66fc3e-32e0-4370-a60e-47a5ced612e5";

const haleopumehanaClosureDetailId = "f09cf894-5e1d-42d3-ad21-e292c7e36dee";
const wailanaClosureDetailId = "84aee6ea-8031-474d-9986-c3e5f243650a";
const palmsClosureDetailId = "4aeb630d-3659-4565-bd11-b798551efb62";
const lakesidecoronetClosureDetailId = "6f1a169d-ccb8-4ddf-9c52-923eebed7f68";

const testPrefix = "test";
const castlesurfPrefix = "castlesurf";
const wailanaPrefix = "wailana";
const patsPrefix = "pats";
const haleopumehanaPrefix = "haleopumehana";
const kahalemoiPrefix = "kahalemoi";
const konareefresortPrefix = "konareefresort";
const lakesidecoronetPrefix = "lakesidecoronet";
const palmsPrefix = "palms";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const Dashboard = () => {
  const { signOut } = useAuthenticator();
  const [value, setValue] = React.useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  React.useEffect(() => {}, []);

  return (
    <div style={{ display: "flex" }}>
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        sx={{ width: "240px" }}
      >
        <Toolbar />
        <Divider />
        <List>
          <ListItemButton>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        </List>
      </Drawer>

      {/* Main Container */}
      <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <AppBar position="static">
          <Toolbar sx={{ backgroundColor: "#cda462" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Image
              alt="logo"
              src="https://darkstarcdn.s3.us-east-2.amazonaws.com/ecs_logo.png"
              maxWidth={150}
              paddingRight={10}
            />
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Admin
            </Typography>
            <Button onClick={signOut} color="inherit">
              Sign out
            </Button>
          </Toolbar>
        </AppBar>
        {/* Main Content */}
        <Container maxWidth="false" sx={{ flexGrow: 1, p: 2 }}>
          <Typography variant="h4" gutterBottom>
            Client Portal Content Administration
          </Typography>
          <Paper elevation={3}>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Castle Surf" {...a11yProps(0)} />
                  <Tab label="Hale O Pumehana" {...a11yProps(1)} />
                  <Tab label="Pat's" {...a11yProps(2)} />
                  <Tab label="Wailana" {...a11yProps(3)} />
                  <Tab label="Ka Hale Moi" {...a11yProps(4)} />
                  <Tab label="Kona Reef Resort" {...a11yProps(5)} />
                  <Tab label="Lakeside Coronet" {...a11yProps(6)} />
                  <Tab label="Palms" {...a11yProps(7)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <NoticeTable
                  clientId={castlesurfClientId}
                  clientPrefix={castlesurfPrefix}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                {" "}
                <ParkingStallClosuresEditor
                  closureDetailId={haleopumehanaClosureDetailId}
                />
                <NoticeTable
                  clientId={haleopumehanaClientId}
                  clientPrefix={haleopumehanaPrefix}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                {" "}
                <NoticeTable
                  clientId={patsClientId}
                  clientPrefix={patsPrefix}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                {" "}
                <ParkingStallClosuresEditor
                  closureDetailId={wailanaClosureDetailId}
                />
                <NoticeTable
                  clientId={wailanaClientId}
                  clientPrefix={wailanaPrefix}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={4}>
                {" "}
                <NoticeTable
                  clientId={kahalemoiClientId}
                  clientPrefix={kahalemoiPrefix}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={5}>
                {" "}
                <NoticeTable
                  clientId={konareefresortClientId}
                  clientPrefix={konareefresortPrefix}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={6}>
                {" "}
                <ParkingStallClosuresEditor
                  closureDetailId={lakesidecoronetClosureDetailId}
                />
                <NoticeTable
                  clientId={lakesidecoronetClientId}
                  clientPrefix={lakesidecoronetPrefix}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={7}>
                {" "}
                <ParkingStallClosuresEditor
                  closureDetailId={palmsClosureDetailId}
                />
                <NoticeTable
                  clientId={palmsClientId}
                  clientPrefix={palmsPrefix}
                />
              </CustomTabPanel>
            </Box>
          </Paper>
        </Container>
      </div>
    </div>
  );
};
