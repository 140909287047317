/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createParkingStallClosures = /* GraphQL */ `
  mutation CreateParkingStallClosures(
    $input: CreateParkingStallClosuresInput!
    $condition: ModelParkingStallClosuresConditionInput
  ) {
    createParkingStallClosures(input: $input, condition: $condition) {
      id
      title
      dateRange
      closureDetails
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateParkingStallClosures = /* GraphQL */ `
  mutation UpdateParkingStallClosures(
    $input: UpdateParkingStallClosuresInput!
    $condition: ModelParkingStallClosuresConditionInput
  ) {
    updateParkingStallClosures(input: $input, condition: $condition) {
      id
      title
      dateRange
      closureDetails
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteParkingStallClosures = /* GraphQL */ `
  mutation DeleteParkingStallClosures(
    $input: DeleteParkingStallClosuresInput!
    $condition: ModelParkingStallClosuresConditionInput
  ) {
    deleteParkingStallClosures(input: $input, condition: $condition) {
      id
      title
      dateRange
      closureDetails
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createNotice = /* GraphQL */ `
  mutation CreateNotice(
    $input: CreateNoticeInput!
    $condition: ModelNoticeConditionInput
  ) {
    createNotice(input: $input, condition: $condition) {
      id
      clientId
      title
      pdfSource
      imgSource
      groupId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateNotice = /* GraphQL */ `
  mutation UpdateNotice(
    $input: UpdateNoticeInput!
    $condition: ModelNoticeConditionInput
  ) {
    updateNotice(input: $input, condition: $condition) {
      id
      clientId
      title
      pdfSource
      imgSource
      groupId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNotice = /* GraphQL */ `
  mutation DeleteNotice(
    $input: DeleteNoticeInput!
    $condition: ModelNoticeConditionInput
  ) {
    deleteNotice(input: $input, condition: $condition) {
      id
      clientId
      title
      pdfSource
      imgSource
      groupId
      createdAt
      updatedAt
      __typename
    }
  }
`;
